@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~@fortawesome/fontawesome-free/css/v4-shims.css";
@import 'mdb-ui-kit/css/mdb.min.css'; // lib
//@import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

$bw-blue-color: #283E4B;
$bw-green-color: #00c851;
$bw-green-aqua-color: #00AC9A;

.bodyPublicPage{
  min-height: 75vh;
  background-color: $light;
}

.bg-bw-primary {
  --bs-bg-opacity: 1;
  background-color: rgba($bw-blue-color, var(--bs-bg-opacity)) !important;
}

.bg-bw-green-aqua {
  --bs-bg-opacity: 1;
  background-color: rgba($bw-green-aqua-color, var(--bs-bg-opacity)) !important;
  color: #ffffff;
}


.bg-bw-green {
  --bs-bg-opacity: 1;
  background-color: rgba($bw-green-color, var(--bs-bg-opacity)) !important;
  color: white;
}

.bw-text-green{
  color: $bw-green-color !important;
}

.bg-bw-transparent {
  --bs-bg-opacity: 1;
  background-color: rgb(0,0,0, 0.5) !important;
  color: white;
}

.bg-bw-transparent .nav-link{
  color: white !important;
}

.bw-form-home {
  background-color: rgb(0,0,0, 0.8) !important;
  border: none;
}

.bw-form-home::placeholder {
  color: white;
}

.bw-title-dark{
  color: $bw-blue-color;
}

.bg-bw-home-search{
  background-image: url("../../public/images/barra_superior.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: -60px;
  padding-top: 60px;
}

.bg-bw2-home-search{
  background-image: url("../../public/images/home_header.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: -120px;
  padding-top: 120px;
}

.bg-bw2-green-aqua{
  background-color: #20C997;
}

.bodyPublicPageOne label{
  color: white !important;
}

.bg-bw2-blue-aqua{
  background-color: #6DB1FF;
}


.bg-bw-primary .nav-link {
  color: #ffffff !important;
}

.min-height-70{
  min-height: 70vh !important;
}

.min-height-60{
  min-height: 60vh !important;
}

.bw-img-overlay{
  position:relative;
  display:inline-block;
}
.bw-img-overlay .bw-img-overlay-div{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  opacity:0;
  transition:opacity 500ms ease-in-out;
}
.bw-img-overlay:hover .bw-img-overlay-div{
  opacity:.9;
}
.bw-img-overlay-div div{
  //position:absolute;
  //top:50%;
  //left:50%;
  ////transform:translate(-50%,-50%);
  //color:#fff;
}

.bw-results-form .form-control{

  color: #ffffff !important;
  text-align: center;

}
.bw-results-form .form-control.disabled, .form-outline .form-control:disabled, .form-outline .form-control[readonly].form-control[readonly]{

  background-color: transparent !important;
  text-align: center;

}

@media only screen and (min-width: 992px) {
  .navbar .navbar-nav .nav-item .nav-link {
    padding: 0 0.5em;
  }
  .navbar .navbar-nav .nav-item:not(:last-child) .nav-link {
    border-right: 2px solid var(--bs-nav-link-color);
  }
}